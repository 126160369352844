<script>
// import axios from "redaxios";
import chroma from "chroma-js";
import { Bar } from "vue-chartjs";

export default {
	extends: Bar,
	data() {
		return {
			options: {
				responsive: true,
				maintainAspectRatio: false,
				title: {
					display: true,
					text: "Solicitudes de Alta de Contratos",
				},
				scales: {
					yAxes: [
						{
							ticks: {
								min: 0,
								precision: 0,
							},
						},
					],
				},
			},
			contratosPorMes: null,
		};
	},
	async mounted() {
		let str = this.$store.getters.getDatosEmpresa;

		const { IdAgente } = str.iss;

		const { primary, secondary } = this.$vuetify.theme.themes.light

		let contratosPalette = chroma
			.bezier([chroma(secondary).brighten(0.5), chroma(secondary), chroma(secondary).darken(0.5)])
			.scale()
			.correctLightness()
			.colors(12);

		let borradoresPalette = chroma
			.bezier([chroma(primary).brighten(0.5), chroma(primary), chroma(primary).darken(0.5)])
			.scale()
			.correctLightness()
			.colors(12);

		let { data: contratos } = await axios({
			method: "GET",
			url: `${process.env.VUE_APP_OUR_API_URL}/contratosVerificados.php`,
			params: {
				token: this.$store.getters.getJwtEmpresa,
				IdAgente,
				codigoContrato: this.codigoContrato,
			},
		})

		let { data: borradores } = await axios({
			method: "GET",
			url: `${process.env.VUE_APP_OUR_API_URL}/contratos.php`,
			headers: {
				Authorization: this.$store.getters.getToken,
			},
			params: {
				token: this.$store.getters.getJwtEmpresa,
				entorno: this.$store.getters.getEntorno
			},
		});

		contratos = contratos.filter(contrato => contrato.Situacion != 'Anulado' && new Date(contrato.FechaContrato).getFullYear() == new Date().getFullYear())
		borradores = borradores.filter(borrador => borrador.Estado == 'Borrador' && new Date(borrador.FechaContrato).getFullYear() == new Date().getFullYear())

		const contratosMes = [...Array(12).keys()].map(mes => (contratos.filter(contrato => new Date(contrato.FechaContrato).getMonth() == mes).length))
		const borradoresMes = [...Array(12).keys()].map(mes => (borradores.filter(borrador => new Date(borrador.FechaContrato).getMonth() == mes).length))

		let results = {
			labels: [
				"Enero",
				"Febrero",
				"Marzo",
				"Abril",
				"Mayo",
				"Junio",
				"Julio",
				"Agosto",
				"Septiembre",
				"Octubre",
				"Noviembre",
				"Diciembre",
			],
			datasets: [
				{
					label: 'Contratos',
					backgroundColor: contratosPalette.map((color) => color + "a0"),
					hoverBackgroundColor: contratosPalette,
					data: contratosMes,
					barPercentage: 1,
				},
				{
					label: 'Borradores',
					backgroundColor: borradoresPalette.map((color) => color + "a0"),
					hoverBackgroundColor: borradoresPalette,
					data: borradoresMes,
					barPercentage: 1,
				},
			],
		};
		this.contratosPorMes = results;
		this.renderChart(this.contratosPorMes, this.options);
		this.$emit('loaded')
	},
};
</script>

<style>
</style>